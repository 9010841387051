@use '../global' as *;
////////////////////////////////////////
/// footer
////////////////////////////////////////
.footer {
    background-color: rgba(#fff , .3);
    padding: 60px 30px 0;
    position: relative;
    z-index: 1;
    @include media('sp'){
        padding: 45px 20px 0;
    }
    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__logo {
        width: 240px;
        margin-bottom: 30px;
        @include media('sp'){
            width: 210px;
            margin-bottom: 20px;
        }
    }

    &__logoLink {}

    &__logoImg {}

    &__info {
        max-width: 640px;
        margin: 0 auto 30px;
        color: #fff;
        font-size: 1.4rem;
        line-height: 1.75;
        @include media('sp'){
            font-size: 1.2rem;
            margin-bottom: 20px;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -10px;
        @include media('sp'){
            margin: 0;
            flex-direction: column;
        }
    }

    &__listItem {
        padding: 10px;
        @include media('sp'){
            width: 200px;
            margin-bottom: 10px;
            padding: 0;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }

    &__listLink {
        padding: 10px 30px;
        background-color: #fff;
        display: block;
        border-radius: 60px;
        color: inherit;
        text-decoration: none;
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        position: relative;
        @include media('sp'){
            font-size: 1.2rem;
            padding: 10px 15px;
            width: 100%;
            justify-content: center;
        }
        &:after{
            content: "";
            width: 6px;
            height: 6px;
            display: block;
            border-top: 1px solid #232323;
            border-right: 1px solid #232323;
            transform: rotate(45deg);
            margin-left: 10px;
            @include media('sp'){
                position: absolute;
                right: 15px;
                top: calc(50% - 3px);
            }
        }
    }

    &__copy {
        display: block;
        margin-top: 45px;
        background-color: #000;
        color: #fff;
        text-align: center;
        margin-left: -30px;
        margin-right: -30px;
        font-size: 1.2rem;
        padding: 10px;
        @include media('sp'){
            padding: 5px 10px;
            font-size: 1.0rem;
            margin-top: 25px;
            margin-left: -20px;
            margin-right: -20px;
        }
    }

    &__copyLink {
        color: #fff;
        text-decoration: none;
    }

    &__copyName {
        color: #fff;
        text-decoration: none;
    }

    &__nav {
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        @include media(){
            display: flex;
        }
    }

    &__navItem {
        width: 25%;
        flex-basis: 25%;
        &:nth-of-type(odd){
            background-color: #fff;
        }
        &:nth-of-type(even){
            background-color: #f5f5f5;
        }
        &:nth-of-type(1) , &:nth-of-type(2) , &:nth-of-type(3){
            .footer__navLink{
                &:before{
                    content: "";
                    width: 19.2px;
                    height: 15px;
                    background: url(../images/icon_movie_black.svg) no-repeat center center / cover;
                    display: block;
                    margin-bottom: 0.25em;
                }
            }
        }
        &:nth-of-type(4){
            .footer__navLink{
                &:before{
                    content: "";
                    width: 19.2px;
                    height: 15px;
                    background: url(../images/icon_menu.svg) no-repeat center center / cover;
                    display: block;
                    margin-bottom: 0.25em;
                }
            }
        }
    }

    &__navLink {
        padding: 10px 5px;
        display: flex;
        justify-content: center;
        color: inherit;
        text-decoration: none;
        font-size: 1.0rem;
        font-weight: 500;
        flex-direction: column;
        align-items: center;
    }
}