/* stylelint-disable declaration-colon-space-after */
@use '../global' as *;
//////////////////////////////
// single
//////////////////////////////

.breadcrumb {
    // background-color: #232323;
    background-color: #000;
    padding: 12px 21px;
    width: 100%;
    margin-bottom: 3vw;
    position: relative;
    z-index: 2;
    @include media(){
        padding: 10px 15px;
        margin-bottom: 20px;
    }
    &__list {
        list-style: none;
        display: flex;
        align-items: center;
        overflow-x: auto;
        white-space: nowrap;
        width: 100%;
        flex-wrap: nowrap;
    }

    &__item {
        display: flex;
        align-items: center;
        &:after{
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 6px solid #ffffff;
            border-right: 0;
            margin: 0 1em;
        }
        &:last-of-type{
            &:after{
                display: none;
            }
        }
    }

    &__link {
        color: #fff;
        text-decoration: none;
        font-size: 1.4rem;
        @include media(){
            font-size: 1.2rem;
        }
    }

    &__linkName {}
}

.single {
    &__contents{
        background-color: #fff;
        padding: 30px 30px 60px 30px;
        @include media(){
            padding: 20px;
        }
    }
    &__header {
        margin: -30px -30px 1.8vw -30px;
        background-color: #000;
        padding: 15px 30px;
        @include media(){
            padding: 0;
            margin: 0 0 15px 0;
            background: transparent;
        }
    }

    &__ttl {
        font-size: 2.7rem;
        font-weight: 700;
        color: #fff;
        line-height: 1.75;
        @include media(){
            font-size: 2.1rem;
            // line-height: 1.66;
            color: #232323;
        }
    }

    &__body {}

    &__meta {}

    &__cats {
        display: flex;
        margin: 0 -5px 1.8vw;
        @include media(){
            margin-bottom: 20px;
        }
    }

    &__catsItem {
        padding: 0 5px;
    }

    &__catsLink {
        border: 1px solid #232323;
        padding: 5px 15px;
        color: #232323;
        text-decoration: none;
        display: block;
        @include media(){
            font-size: 1.2rem;
            padding: 3px 10px;
        }
        &:hover{
            background-color: #232323;
            color: #fff;
        }
    }

    &__eyecatch {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 3vw;
        @include media(){
            margin-bottom: 30px;
        }
    }

    &__eyecatchImg {}

    &__eyecatchCopy {
        font-size: 1.4rem;
        margin-top: 10px;
        @include media(){
            font-size: 1.0rem;
            margin-top: 5px;
        }
    }

    &__eyecatchCopyText {}

    &__copy {
        border-left: 2px solid #00b7ff;
        border-right: 2px solid #f09;
        padding: 20px 25px;
        position: relative;
        font-size: 2.1rem;
        color: #f09;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom: 3vw;
        @include media(){
            padding: 10px 15px;
            margin-bottom: 30px;
            font-size: 1.8rem;
        }
        &:before{
            content: "";
            height: 2px;
            display: block;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(to left, #ff0099, #00b7ff);
        }
        &:after{
            content: "";
            height: 2px;
            display: block;
            width: 100%;
            position: absolute;
            bottom: 0;
            right: 0;
            background: linear-gradient(to left, #ff0099, #00b7ff);
        }
    }

    &__recommend {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2.1vw;
        @include media(){
            margin-bottom: 20px;
        }
        &:nth-of-type(odd){
        }
    }

    &__recommendDt {
        width: 200px;
        padding: 20px;
        font-weight: 600;
        font-size: 1.8rem;
        &:nth-of-type(odd){
            background-color: #f2f2f2;
        }
        @include media(){
            padding: 15px 15px;
            width: 120px;
            flex-basis: 120px;
            font-size: 1.6rem;
        }
    }

    &__recommendDd {
        width: calc(100% - 200px);
        padding: 20px;
        @include media(){
            width: calc(100% - 120px);
            flex-basis: calc(100% - 120px);
            padding: 15px 15px;
        }
        &:nth-of-type(odd){
            background-color: #f2f2f2;
        }
        .single__recommendDdStar{
            position: relative;
            font-size: 1.8rem;
            font-weight: 500;
            position: relative;
            display: inline-block;
            color: #ababab;
            @include media(){
                font-size: 1.6rem;
            }
            &:before , &:after{
                content: '★★★★★';
            }
            &:after{
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                overflow: hidden;
                white-space: nowrap;
                // color: #ffe54a;
                color: #f09;
            }
        }
        
        
        &[data-rate="5"]{
            .single__recommendDdStar{
                &:after{
                    width: 100%;
                }
            }
            
        }
        &[data-rate="4.5"]{
            .single__recommendDdStar{
                &:after{
                    width: 90%;
                }
            }
        }
        &[data-rate="4"]{
            .single__recommendDdStar{
                &:after{
                    width: 80%;
                }
            }
        }
        &[data-rate="3.5"]{
            .single__recommendDdStar{
                &:after{
                    width: 70%;
                }
            }
        }
        &[data-rate="3"]{
            .single__recommendDdStar{
                &:after{
                    width: 60%;
                }
            }
        }
        &[data-rate="2.5"]{
            .single__recommendDdStar{
                &:after{
                    width: 50%;
                }
            }
        }
        &[data-rate="2"]{
            .single__recommendDdStar{
                &:after{
                    width: 40%;
                }
            }
        }
        &[data-rate="1.5"]{
            .single__recommendDdStar{
                &:after{
                    width: 30%;
                }
            }
        }
        &[data-rate="1.0"]{
            .single__recommendDdStar{
                &:after{
                    width: 20%;
                }
            }
        }
        &[data-rate="0.5"]{
            .single__recommendDdStar{
                &:after{
                    width: 10%;
                }
            }
        }
    }

    &__vods {
        display: flex;
        align-items: center;
        margin-bottom: 2.1vw;
        @include media(){
            flex-wrap: wrap;
            margin: 20px 0;
        }
    }

    &__vodsItem {
        width: 50%;
        flex-basis: 50%;
        @include media(){
            width: 100%;
            flex-basis: 100%;
            &:first-of-type{
                margin-bottom: 5px;
            }
        }
        a{
            color: #fff;
            padding: 20px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            &:after{
                content: "";
                display: block;
                width: 6px;
                height: 6px;
                border-right: 1px solid #fff;
                border-top: 1px solid #fff;
                transform: rotate(45deg);
                margin-left: .5em;
            }
        }
        &:first-of-type{
            a{
                background-color: #44A2C6;
                border: 1px solid #44A2C6;
                &:hover{
                    background-color: transparent;
                    color: #44A2C6;
                    &:after{
                        border-color: #44A2C6;
                    }
                }
            }
        }
        &:last-of-type{
            a{
                background-color: #00A6BA;
                border: 1px solid #00A6BA;
                &:hover{
                    color: #00A6BA;
                    background-color: transparent;
                    &:after{
                        border-color: #00A6BA;
                    }
                }
            }
        }
    }

    &__vodsLink {
        color: #fff;
        padding: 20px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        &:after{
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            border-right: 1px solid #fff;
            border-top: 1px solid #fff;
            transform: rotate(45deg);
            margin-left: .5em;
        }
    }

    &__vodsLink_type_unext {
        background-color: #44A2C6;
        border: 1px solid #44A2C6;
        &:hover{
            background-color: transparent;
            color: #44A2C6;
            &:after{
                border-color: #44A2C6;
            }
        }
    }

    &__vodsLink_type_prime {
        background-color: #00A6BA;
        border: 1px solid #00A6BA;
        &:hover{
            color: #00A6BA;
            background-color: transparent;
            &:after{
                border-color: #00A6BA;
            }
        }
    }

    &__main {}

    &__section {
        margin-bottom: 60px;
        @include media(){
            margin-bottom: 45px;
        }
        &:last-of-type{
            margin-bottom: 0;
        }
    }

    &__sectionTtl {
        font-size: 2.4rem;
        color: #232323;
        display: flex;
        align-items: center;
        font-weight: 500;
        border-bottom: 1px solid #232323;
        padding-bottom: 15px;
        margin-bottom: 30px;
        @include media(){
            font-size: 1.8rem;
            padding-bottom: 10px;
            margin-bottom: 25px;
        }
        &:before{
            content: "";
            display: block;
            width: 22.4px;
            height: 19.11px;
            min-width: 22.4px;
            background: url(../images/icon_headline_02.svg) no-repeat center center / cover;
            margin-right: .5em;
        }
    }

    &__sectionBody {
        h2{}
        h3{
            font-size: 2.4rem;
            color: #232323;
            display: flex;
            align-items: center;
            font-weight: 500;
            border-bottom: 1px solid #232323;
            padding-bottom: 15px;
            margin-bottom: 30px;
            @include media(){
                font-size: 1.8rem;
                padding-bottom: 10px;
                margin-bottom: 25px;
            }
            &:before{
                content: "";
                display: block;
                width: 22.4px;
                height: 19.11px;
                min-width: 22.4px;
                background: url(../images/icon_headline_02.svg) no-repeat center center / cover;
                margin-right: .5em;
            }
        }
        h4{
            font-size: 1.2em;
            font-weight: 600;
            color: #f09;
            margin-bottom: 1em;
        }
        p{
            line-height: 1.75;
            margin-bottom: 1.5em;
            &:last-of-type {
                // margin-bottom: 0;
                
            }
        }
        img{
            max-width: 100%;
            height: auto;
            vertical-align: bottom;
        }
        iframe{
            width: 100%;
            height: auto;
            aspect-ratio: 16 / 9;
        }
        a{
            color: inherit;
            text-decoration: underline;
            &:hover{
                text-decoration: none;
            }
        }
        .google-auto-placed{
            margin: 1.5em 0;
        }
        figure{
            margin: 1.5em 0;
        }
    }
}

.tableOfContents {
    border: 1px solid #232323;
    padding: 30px 30px;
    margin-bottom: 3.6vw;
    @include media(){
        padding: 20px;
        margin-bottom: 30px;
    }
    &__ttl {
        font-size: 1.8rem;
        margin-bottom: .5em;
        padding-bottom: .5em;
        border-bottom: 1px solid #232323;
        @include media(){
            font-size: 1.6rem;
        }
    }

    &__list {
        list-style: none;
    }

    &__item {
        border-bottom: 1px solid #ddd;
        &:last-of-type{
            border-bottom: none;
            .tableOfContents__link{
                padding-bottom: 0;
            }
        }
    }

    &__link {
        line-height: 2;
        text-decoration: none;
        color: #232323;
        padding: 10px 10px;
        display: block;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.4rem;
        @include media(){
            font-size: 1.2rem;
            padding: 10px 10px 10px 0;
            line-height: 1.5;
        }
        
        &:after{
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            border-top: 1px solid #232323;
            border-right: 1px solid #232323;
            transform: rotate(135deg);
        }
        &:hover{
            text-decoration: underline;
        }
    }
}

.singleYoutube {
    iframe{
        aspect-ratio: 16 / 9;
        width: 100%;
        height: auto;
    }
}

.sectionHead {}

.singleOverView {}

.singleStory {}

.wp-caption {
    width: auto !important;
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    @include media(){
        margin-bottom: 20px;
    }
}

.alignnone {}

.size-full {}

.wp-caption-text {
    font-size: 1.4rem;
    margin-top: 10px;
    margin-bottom: 0 !important;
    @include media(){
        font-size: 1.0rem;
        margin-top: 5px;
    }
}

.singleReview {}

.singleHilights {}

.singleOthers {
    &__section {
        padding: 20px;
        @include media(){
            padding: 10px 15px;
        }
        &:nth-of-type(odd){
            background-color: #f2f2f2;
        }
    }

    &__sectionTtl {
        font-size: 1.8rem;
        font-weight: 500;
        margin-bottom: .5em;
        @include media(){
            font-size: 1.6rem;
            margin-bottom: .25em;
        }
    }

    &__sectionPara {}

    &__sectionList {
        li {
            display: flex;
            line-height: 2;
            &:before{
                content: "";
                width: 6px;
                height: 6px;
                background-color: #232323;
                margin-right: .5em;
                position: relative;
                top: 15px;
                @include media(){
                    top: 12px;
                }
            }
        }
    }

    &__sectionItem {
        
    }

    &__sectionLink {
        color: inherit;
        text-decoration: underline;
    }
}

.vodList {
    display: flex;
    &__item {
        width: 25%;
        flex-basis: 25%;
    }
    &__link {
        display: flex;
        align-items: center;
        padding: 20px 10px;
        justify-content: center;
        background-color: #f2f2f2;
        color: #dddddd;
        text-decoration: none;
        @include media(){
            font-size: 1.0rem;
            letter-spacing: -.05em;
        }
    }
    &__link_type_none {}

    &__link_type_netflix{
        background-color: #000;
        color: #fff;
    }

    &__link_type_amazonprime{
        background-color: #44A2C6;
        color: #fff;
    }

    &__link_type_hulu{
        background-color: #80c04b;
        color: #fff;
    }

    &__link_type_unext{
        background-color: #00A6BA;
        color: #fff;
    }
}

.relatedArticles {
    &__ttl {
        background-color: rgba(#fff , .5);
        font-size: 2.4rem;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        @include media('sp'){
            font-size: 2.1rem;
        }
        &:before{
            content: "";
            display: block;
            background: url(../images/icon_main_ttl.svg) no-repeat center center / cover;
            width: 24px;
            height: 21.3px;
            position: relative;
            top: 2px;
            margin-right: 5px;
            @include media('sp'){
                width: 21px;
                height: 18px;
                top: 1px;
            }
        }
    }

    &__list {
        .articleList__item{
            width: 33.333%;
            flex-basis: 33.333%;
            @include media('lt'){
                width: 50%;
                flex-basis: 50%;
            }
            @include media('tab'){
                width: 100%;
                flex-basis: 100%;
            }
            @include media('sp'){
                margin-bottom: 20px;
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
    }
}

.single__hash{
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    &Item{
        padding: .25em .5em;
        display: flex;
        align-items: center;
        &:before{
            content: "#";
            font-weight: 900;
            margin-right: 0.25em;
        }
    }
    &Link{
        color: inherit;
        text-decoration: none;
        display: flex;
        &:hover{
            text-decoration: underline;
        }
    }
}