@use '../global' as *;
////////////////////////////////////////
/// searchForm
////////////////////////////////////////
.searchForm {
    &__inner {
        position: relative;
        display: block;
    }

    &__text {
        width: 100%;
        background: transparent;
        border:none;
        border-bottom: 1px solid #232323;
        outline: none;
        appearance: none;
        border-radius: 0;
        padding: 10px 0;
        font-size: 1.6rem;
    }

    &__bt{
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: transparent;
        background: url(../images/icon_search.svg) no-repeat center center / cover;
        outline: none;
        appearance: none;
        display: block;
        position: absolute;
        top: 12px;
        right: 0;
        border: none;
    }
}