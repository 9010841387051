@use '../global' as *;
////////////////////////////////////////
/// pagination
////////////////////////////////////////
.pagination {
    &__list {
        display: flex;
        margin: 40px -5px 0 -5px;
        justify-content: center;
        @include media('sp'){
            margin-top: 30px;
        }
    }

    &__item {
        list-style: none;
        padding: 0 5px;
    }

    &__item_type_prev {
        .pagination__link{
            &:after{
                content: "";
                width: 6px;
                height: 6px;
                display: block;
                border-top: 1px solid #fff;
                border-left: 1px solid #fff;
                transform: rotate(-45deg);
            }
            &_current{
                background-color: #696969;
                color: #ccc;
            }
        }
        
    }

    &__link {
        display: flex;
        width: 42px;
        height: 42px;
        background-color: rgba(#fff, .3);
        color: #fff;
        text-decoration: none;
        justify-content: center;
        align-items: center;
        @include media('sp'){
            width: 36px;
            height: 36px;
        }
        &:hover{
            background-color: #f09;
        }
        &_current {
            pointer-events: none;
            background-color: #f09;
        }
    }

    &__link_isCurrent {
        pointer-events: none;
    }

    &__item_type_next {
        .pagination__link{
            &:after{
                content: "";
                width: 6px;
                height: 6px;
                display: block;
                border-top: 1px solid #fff;
                border-right: 1px solid #fff;
                transform: rotate(45deg);
            }
            &_current{
                background-color: #696969;
                color: #ccc;
            }
        }
    }
}