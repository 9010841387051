@use '../global' as *;
////////////////////////////////////////
/// moreBt
////////////////////////////////////////
.moreBt {
    background-color: rgba(#000 , .9);
    color: #fff;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    max-width: 100%;
    height: 60px;
    margin: 0 auto 0;
    border: 1px solid rgba(#000 , .9);
    text-decoration: none;
    &:hover{
        background-color: rgba(#000 , .5);
    }
    &:after{
        content: "";
        width: 6px;
        height: 6px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        transform: rotate(45deg);
        display: block;
        position: absolute;
        right: 20px;
        top: calc(50% - 3px);
    }
}