/* stylelint-disable declaration-colon-space-after */
@use '../global' as *;
//////////////////////////////
// categoryInfo
//////////////////////////////


.main{
    width: 100%;
}

.categoryInfo {
    margin-top: 30px;
    margin-bottom: 45px;
    overflow: hidden;
    @include media(){
        margin-top: 20px;
        margin-bottom: 30px;
        // margin-left: -25px;
        // margin-right: -25px;
        // width: calc(100% + 50px);
        // overflow: hidden;
    }
    &__ttl {
        margin-bottom: 1em;
    }

    &__contents {
        display: flex;
        flex-wrap: wrap;
    }

    &__fig {
        width: 50%;
        flex-basis: 50%;
        @include media(){
            width: 100vw;
            flex-basis: 100vw;
        }
    }

    &__img {
        max-width: 100%;
        height: auto;
        // height: 100%;
        object-fit: cover;
        width: 100%;
    }

    &__text {
        width: 50%;
        flex-basis: 50%;
        display: flex;
        align-items: center;
        padding: 30px;
        background-color: rgba(#000 , .5);
        @include media(){
            width: 100vw;
            flex-basis: 100vw;
            max-width: 100vw;
            padding: 20px 25px;
        }
    }

    &__desc {
        color: #fff;
        line-height: 2;
    }
}

.actors__list{
    .articleList__item{
        &:nth-of-type(odd){
            .articleList__figLink{
                position: relative;
                z-index: 1;
                &:after{
                    content: "";
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-color: rgba(#000 , .05);
                }
            }
        }
    }
}