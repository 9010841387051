@use '../global' as *;
////////////////////////////////////////
/// articleList
////////////////////////////////////////
.articleList {
    display: flex;
    flex-wrap: wrap;
    &__item {
        background-color: rgba(#fff,.3);
        width: 33.333%;
        flex-basis: 33.333%;
        position: relative;
        @include media('lt'){
            width: 50%;
            flex-basis: 50%;
        }
        @include media('tab'){
            width: 100%;
            flex-basis: 100%;
        }
        @include media('sp'){
            margin-bottom: 20px;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
        &:nth-of-type(1) , &:nth-of-type(2) , &:nth-of-type(6) , &:nth-of-type(7) , &:nth-of-type(11) , &:nth-of-type(12) , &:nth-of-type(16) , &:nth-of-type(17){
            width: 50%;
            flex-basis: 50%;
            @include media('tab'){
                width: 100%;
                flex-basis: 100%;
            }
        }
        &:nth-of-type(odd){
            background-color: rgba(#fff,.5);
        }
    }

    &__fig {
        overflow: hidden;
    }

    &__figLink {
        overflow: hidden;
        display: block;
        &:after{
            padding-bottom: 100%;
        }
        &:hover{
            .articleList__img{
                animation-name: blur;
                animation-duration: 1s;
                animation-fill-mode: forwards;
            }
        }
    }

    &__img {
        aspect-ratio: 16 / 10;
        object-fit: cover;
        filter: grayscale(100%);
        transition: filter 0.3s ease;
        width: 100%;
        height: auto;
    }

    &__vod {
        display: flex;
        margin-top: -32px;
        position: relative;
        z-index: 1;
    }

    &__vodItem {
        width: 25%;
        flex-basis: 25%;
    }

    &__vodLink {
        background-color: #000;
        color: #fff;
        font-size: 1.2rem;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        height: 32px;
        position: relative;
        &:hover{
            top: -2px;
        }
        @include media('tab'){}
        @include media('lt'){
            font-size: 1.0rem;
        }
    }

    &__vodLink_type_netflix {}

    &__vodLink_type_amazonprime {
        background-color: #44A2C6;
    }

    &__vodLink_type_hulu {
        background-color: #80c04b;
    }

    &__vodLink_type_unext {
        background-color: #00A6BA;
    }

    &__text {
        padding: 20px 20px 50px;
        display: flex;
        flex-direction: column;
    }
    &__top{}

    &__cats {
        display: flex;
        flex-wrap: wrap;
        margin: -3px;
    }

    &__catsItem {
        padding: 3px;
    }

    &__catsLink {
        background-color: rgba(#fff,.3);
        color: #fff;
        text-decoration: none;
        padding: 3px 10px;
        font-size: 1.2rem;
        @include media('sp'){
            font-size: 1.0rem;
        }
        &:hover{
            background-color: #ff0099;
            color: #fff;
        }
    }

    &__date{
        color: #fff;
        font-size: 1.4rem;
        margin-top: 15px;
        display: block;
        @include media('sp'){
            font-size: 1.2rem;
            margin-top: 10px;
        }
    }

    &__ttl {
        margin-top: 5px;
        margin-bottom: 10px;
        @include media('sp'){}
    }

    &__ttlLink {
        font-size: 2.1rem;
        font-weight: 700;
        color: #fff;
        text-decoration: none;
        @include media('sp'){
            font-size: 1.8rem;
            margin-bottom: 5px;
        }
        &:hover{
            color: #ff0099;
        }
    }

    &__excerpt {
        font-size: 1.2rem;
        color: #fff;
        line-height: 1.75;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }

    &__recommend {
        display: flex;
        position: absolute;
        bottom: 20px;
    }

    &__recommendDt {
        font-size: 1.6rem;
        display: flex;
        font-weight: 700;
        color: #fff;
        @include media('sp'){
            font-size: 1.4rem;
        }
        &:after{
            content: ":";
            padding: 0 .5em;
            font-weight: 700;
            color: #fff;
        }
    }

    &__recommendDd {
        font-size: 1.6rem;
        font-weight: 700;
        color: #fff;
        position: relative;
        z-index: 0;
        display: inline-block;
        white-space: nowrap;
        color: #ababab;
        @include media('sp'){
            font-size: 1.4rem;
        }
        &:before , &:after{
            content: '★★★★★';
        }
        &:after{
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            overflow: hidden;
            white-space: nowrap;
            color: #ffe54a; 
        }
        &[data-rate="5"]{
            &:after{
                width: 100%;
            }
        }
        &[data-rate="4.5"]{
            &:after{
                width: 90%;
            }
        }
        &[data-rate="4"]{
            &:after{
                width: 80%;
            }
        }
        &[data-rate="3.5"]{
            &:after{
                width: 70%;
            }
        }
        &[data-rate="3"]{
            &:after{
                width: 60%;
            }
        }
        &[data-rate="2.5"]{
            &:after{
                width: 50%;
            }
        }
        &[data-rate="2"]{
            &:after{
                width: 40%;
            }
        }
        &[data-rate="1.5"]{
            &:after{
                width: 30%;
            }
        }
        &[data-rate="1.0"]{
            &:after{
                width: 20%;
            }
        }
        &[data-rate="0.5"]{
            &:after{
                width: 10%;
            }
        }
    }
}