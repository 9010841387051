@use '../global' as *;
////////////////////////////////////////
/// header
////////////////////////////////////////
.gNav {
    max-width: 540px;
    background-color: rgba(#fff, .8);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    padding: 60px 30px;
    transform: translateY(-200%);
    transition: transform 0.3s ease;
    max-height: 100vh;
    overflow: scroll;
    @include media(){
        max-width: 100%;
    }
    &__head{
        font-size: 2.4rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 20px;
    }
    &__list {}

    &__item {
        border-bottom: 1px solid #232323;
        &.jsOpen{
            .dropMenu{
                margin: -3px 0 10px 0;
            }
            .gNav__drop{
                &:before{
                    display: none;
                }
            }
        }
    }

    &__link {
        color: inherit;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: space-between;
        width: 100%;
        text-decoration: none;
        padding: 15px 0;
        &:after{
            content: "";
            position: relative;
            width: 6px;
            height: 6px;
            border-top: 1px solid #232323;
            border-right: 1px solid #232323;
            transform: rotate(45deg);
            position: absolute;
            right: 2px;
            top: calc(50% - 3px);
        }
    }

    .gNav__drop{
        position: relative;
        color: inherit;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: space-between;
        width: 100%;
        text-decoration: none;
        padding: 15px 0;
        &:after{
            content: "";
            width: 12px;
            height: 1px;
            display: block;
            background-color: #232323;
            position: absolute;
            right: 0;
            top: calc(50% - .5px);
            transform: none;
        }
        &:before{
            content: "";
            width: 1px;
            height: 12px;
            position: absolute;
            right: 5.5px;
            top: 21.5px;
            background-color: #232323;
            @include media(){
                top: 19.5px;
            }
        }
    }
}

.dropMenu {
    height: 0;
    overflow: hidden;
    transition: all 0.5s;
    display: flex;
    flex-wrap: wrap;
    &__item {
        padding: 3px;
    }

    &__link {
        color: inherit;
        display: flex;
        align-items: center;
        text-decoration: none;
        background-color: #fff;
        padding: 5px 10px;
        font-size: 1.4rem;
        @include media(){
            font-size: 1.2rem;
        }
    }
}

.cast__list_type_nav{
    .cast__link{
        background-color: #fff;
        color: inherit;
        &:hover{
            color: #fff;
        }
    }
}