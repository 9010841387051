@use 'global' as *;
.u {
    &_d_n { display: none; }
    &_d_b { display: block; }
    &_d_f { display: flex; }
    @include media ("se") {
        &_se_d_n { display: none; }
        &_se_d_b { display: block; }
        &_se_d_f { display: flex; }
    }
    @include media ("sp") {
        &_sp_d_n { display: none; }
        &_sp_d_b { display: block; }
        &_sp_d_f { display: flex; }
    }
    @include media ("tab") {
        &_tab_d_n { display: none; }
        &_tab_d_b { display: block; }
        &_tab_d_f { display: flex; }
    }
    @include media("pc") {
        &_pc_d_n { display: none; }
        &_pc_d_b { display: block; }
        &_pc_d_f { display: flex; }
    }

    &_ta_l { text-align: left; }
    &_ta_c { text-align: center; }
    &_ta_r { text-align: right; }
    @include media () {
        &_sp_ta_l { text-align: left; }
        &_sp_ta_c { text-align: center; }
        &_sp_ta_r { text-align: right; }
    }
    @include media ("tab") {
        &_tab_ta_l { text-align: left; }
        &_tab_ta_c { text-align: center; }
        &_tab_ta_r { text-align: right; }
    }
    @include media("pc") {
        &_pc_ta_l { text-align: left; }
        &_pc_ta_c { text-align: center; }
        &_pc_ta_r { text-align: right; }
    }

    &_jc_fs { justify-content: flex-start; }
    &_jc_c { justify-content: center; }
    &_jc_fe { justify-content: flex-end; }
    &_jc_sb { justify-content: space-between; }
    &_jc_sa { justify-content: space-around; }
    @include media () {
        &_sp_jc_fs { justify-content: flex-start; }
        &_sp_jc_c { justify-content: center; }
        &_sp_jc_fe { justify-content: flex-end; }
        &_sp_jc_sb { justify-content: space-between; }
        &_sp_jc_sa { justify-content: space-around; }
    }
    @include media ("tab") {
        &_tab_jc_fs { justify-content: flex-start; }
        &_tab_jc_c { justify-content: center; }
        &_tab_jc_fe { justify-content: flex-end; }
        &_tab_jc_sb { justify-content: space-between; }
        &_tab_jc_sa { justify-content: space-around; }
    }
    @include media("pc") {
        &_pc_jc_fs { justify-content: flex-start; }
        &_pc_jc_c { justify-content: center; }
        &_pc_jc_fe { justify-content: flex-end; }
        &_pc_jc_sb { justify-content: space-between; }
        &_pc_jc_sa { justify-content: space-around; }
    }
}
