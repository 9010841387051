@use '../global' as *;
////////////////////////////////////////
/// sectionTtl
////////////////////////////////////////
.sectionTtl {
    color: #fff;
    font-size: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
    line-height: 1;
    @include media('sp'){
        font-size: 2.1rem;
    }
    &:before{
        content: "";
        display: block;
        background: url(../images/icon_main_ttl.svg) no-repeat center center / cover;
        width: 24px;
        height: 21.3px;
        position: relative;
        top: 2px;
        margin-right: 5px;
        @include media('sp'){
            width: 21px;
            height: 18px;
            top: 1px;
        }
    }
}