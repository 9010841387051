@use '../global' as *;
////////////////////////////////////////
/// main
////////////////////////////////////////
.mainWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 6vw;
}

.main {
    width: 77vw;
    flex-basis: 77vw;
    padding-right: 3vw;
    @include media('lt'){
        width: calc(100% - 240px);
        flex-basis: calc(100% - 240px);
    }
    
    @include media('sp'){
        width: 100%;
        flex-basis: 100%;
        padding-right: 0;
        margin-bottom: 60px;
    }
    &_type_full{
        width: 100%;
        flex-basis: 100%;
        padding-right: 0;
        @include media('lt'){
            width: 100%;
            flex-basis: 100%;
        }
    }
}

.top {
}