@use 'global' as *;

html {
    font-size: 62.5%;
    height: auto;
    overflow: visible;
}

body {
    font-size: 1.6rem;
    background-color: rgba(#000,.5);
    font-family: "Noto Sans JP", sans-serif;
    font-weight: 400;
    color: #232323;
    @include media () {
        font-size: 1.4rem;
    }
    &.navOpened{
        overflow: hidden;
        height: 100%;
    }
}

.container {
    margin: 0 auto;
    max-width: 100%;
    padding-left: 3vw;
    padding-right: 3vw;
    width: 100%;
    @include media('sp'){
        padding-left: 20px;
        padding-right: 20px;
    }
}

img{
    vertical-align: bottom;
}

@keyframes blur{
    0%{
        filter: blur(0px) grayscale(100%);
        transform: scale(1);
    }
  
    50%{
        filter: blur(8px) grayscale(50%);
        transform: scale(1.1);
    }
  
    100%{
        filter: blur(0px) grayscale(0%);
        transform: scale(1.05);
    }
  }

//////////////////////////////
// main
//////////////////////////////
.main {
    display: block;
    position: relative;
    z-index: 1;
}

.wrapper{
    position: relative;
    background-color: rgba(#000 , .5);
    &.navOpened{
        // filter: blur(8px)
        .header__name , .header__logo , .topSlider , .mainWrapper , .footer{
            filter: blur(8px);
        }
    }
    .header__name , .header__logo , .topSlider , .mainWrapper , .footer{
        transition: filter 0.5s 0s ease;
    }
    @include media(){
        padding-bottom: 52.5px;
    }
    &.isScrolled{
        .header__inner{
            position: fixed;
            top: 0;
            width: 100%;
        }
    }
}
.pageBg{
    position: fixed;
    display: block;
    left: -8px;
    top: -8px;
    width: calc(100vw + 16px);
    height: calc(100vh + 16px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.5;
    filter: blur(8px);
    z-index: 0;
    &.navOpened{
        z-index: 1;
    }
}