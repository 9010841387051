/* stylelint-disable declaration-colon-space-after */
@use '../global' as *;
//////////////////////////////
// index
//////////////////////////////

.topSlider {
    margin-bottom: 60px;
    // margin-top: 110px;
    @include media('sp'){
        // margin-top: 94px;
        margin-bottom: 45px;
    }
    &__list {}

    &__item {
        width: 840px;
        flex-basis: 840px;
        @include media(){
            width: calc(100vw - 40px);
            flex-basis: calc(100vw - 40px);
        }
    }

    .swiper-slide-active{
        .topSlider__figImg{
            filter: grayscale(0%);
        }
    }

    &__inner {}

    &__fig {}

    &__figLink {
        display: block;
        overflow: hidden;
        &:hover{
            .topSlider__figImg{
                animation-name: blur;
                animation-duration: 1s;
                animation-fill-mode: forwards;
            }
        }
    }

    &__figImg {
        width: 100%;
        // aspect-ratio: 16 / 12;
        aspect-ratio: 16 / 9;
        object-fit: cover;
        filter: grayscale(100%);
        transition: filter 0.3s ease;
    }
    

    &__text {
        position: relative;
        z-index: 2;
        left: -15px;
        top: -60px;
        margin-bottom: -45px;
        width: calc(100% - 90px);
        @include media('sp'){
            left: -10px;
            top: -30px;
            margin-bottom: -30px;
            width: calc(100% - 45px);
        }
    }

    &__cats {
        display: flex;
        margin: 0 -1px;
    }

    &__catsItem {
        padding: 0 1px;
    }

    &__catsLink {
        display: block;
        // background-color: rgba(#fff,.5);
        background-color: rgba(#fff,1);
        color: inherit;
        font-size: 1.4rem;
        text-decoration: none;
        padding: 5px 15px;
        line-height: 1;
        transition: color , background-color 0.3s ease;
        color: #232323;
        @include media('sp'){
            font-size: 1.0rem;
        }
        &:hover{
            background-color: #000;
            color: #fff;
        }
    }

    &__ttl {
        
    }

    &__ttlLink {
        color: inherit;
        text-decoration: none;
        // background-color: rgba(#fff, .5);
        background-color: rgba(#fff,1);
        color: #232323;
        font-size: 2.1rem;
        line-height: 1.75;
        text-decoration: none;
        font-weight: 400;
        display: inline-block;
        margin-top: 2px;
        padding: 0px 10px;
        transition: color , background-color 0.3s ease;
        &:hover{
            background-color: #000;
            color: #fff;
        }
        @include media('sp'){
            // font-size: 1.6rem;
        }
    }
}
.column{
    margin-top: 60px;
    @include media(){
        margin-top: 45px;
    }
}