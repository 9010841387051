@use '../global' as *;
////////////////////////////////////////
/// sidebar
////////////////////////////////////////
.sidebar {
    width: 17vw;
    flex-basis: 17vw;
    position: relative;
    z-index: 1;
    @include media('lt'){
        width: 240px;
        flex-basis: 240px;
    }
    @include media('sp'){
        width: 100%;
        flex-basis: 100%;
    }
}

.siderbarContents {
    background-color: rgba(#fff,.3);
    margin-bottom: 3vw;
    padding: 15px 20px 20px 20px;
    &:last-of-type{
        margin-bottom: 0;
    }
    @include media('sp'){
        margin-bottom: 20px;
    }
    &__ttl {
        color: #fff;
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: .75em;
        text-align: center;
        @include media('sp'){}
    }

    &__inner {}
}

.popArticles {
    counter-reset: listnum;
    &__item {
        list-style: none;
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 15px;
        &:last-of-type{
            margin-bottom: 0;
        }
        &:before{
            counter-increment: listnum;
        	content: counter(listnum);
            position: absolute;
            left: 0;
            top: 0;
            font-size: 1.2rem;
            width: 20px;
            height: 20px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 2;
        }
    }

    &__fig {
        width: 35%;
        flex-basis: 35%;
        @include media('sp'){
            width: 100px;
            flex-basis: 100px;
        }
    }

    &__figLink {
        display: block;
        overflow: hidden;
        &:hover{
            .popArticles__figImg{
                animation-name: blur;
                animation-duration: 1s;
                animation-fill-mode: forwards;
            }
        }
    }

    &__figImg {
        aspect-ratio: 1 / 1;
        object-fit: cover;
        filter: grayscale(100%);
        transition: filter 0.3s ease;
    }

    &__text {
        width: 65%;
        flex-basis: 65%;
        padding-left: 15px;
        @include media('sp'){
            width: calc(100% - 100px);
            flex-basis: calc(100% - 100px);
        }
    }

    &__ttl {}

    &__ttlLink {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.4;
        text-decoration: none;
        color: #fff;
        &:hover{
            color: #f09;
        }
    }
}

.cats {
    margin-bottom: 1.5vw;
    @include media('sp'){
        margin-bottom: 20px;
    }
    &_type_kw {
        .cats__link{
            &:before{
                content: "#";
                font-weight: 900;
                margin-right: .25em;
            }
        }
    }
    &_type_vod{
        .cats__link{
            &:before{
                content: "";
                width: 18.85px;
                height: 13.75px;
                background: url(../images/icon_vod.svg) no-repeat center center / cover;
                display: block;
                margin-right: .25em;
                position: relative;
                top: 1px;
            }
        }
    }
    &_type_genre{
        .cats__link{
            &:before{
                content: "";
                width: 19.2px;
                height: 15px;
                background: url(../images/icon_movie.svg) no-repeat center center / cover;
                display: block;
                margin-right: .25em;
                position: relative;
                top: 1px;
            }
        }
    }
    &:last-of-type{
        margin-bottom: 0;
    }
    &__ttl {
        margin-bottom: .5em;
    }

    &__ttlLink {
        font-size: 1.6rem;
        color: #fff;
        display: flex;
        text-decoration: none;
        @include media('sp'){
            font-size: 1.4rem;
        }
        &:before{
            content: "-";
            margin-right: .5em;
        }
        &:hover{
            text-decoration: underline;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: -3px;
    }

    &__item {
        padding: 3px;
    }

    &__link {
        padding: 5px 10px;
        font-size: 1.2rem;
        background-color: rgba(#fff , .3);
        color: #fff;
        font-weight: 500;
        text-decoration: none;
        display: flex;
        align-items: center;
        &:hover{
            background-color: #f09;
        }
    }
}

.recommendation {
    &__ttl {}

    &__list {}
}

.cast {
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: -3px;
    }

    &__item {
        padding: 3px;
        width: 20%;
        flex-basis: 20%;
    }

    &__link {
        padding: 5px 10px;
        font-size: 1.2rem;
        background-color: rgba(#fff , .3);
        color: #fff;
        font-weight: 500;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
            background-color: #f09;
        }
    }
}