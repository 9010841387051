@charset 'utf-8';
$responsive: (
	"se": "screen and (max-width: 321px)",
	"spm": "screen and (max-width: 480px)",
	"sp": "screen and (max-width: 768px)",
	"tab": "screen and (max-width: 1050px)",
	"lt": "screen and (max-width: 1260px)",
	"pc": "screen and (max-width: 1400px)",
	"4k": "screen and (max-width: 2560px)",
) !default;
@mixin media($breakpoint: sp) {
	@media #{map-get($responsive, $breakpoint)} {
		@content;
	}
}
