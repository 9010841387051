@charset "UTF-8";
/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: http://meyerweb.com
	HTML5 Doctor				:: http://html5doctor.com
	and the HTML5 Boilerplate	:: http://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

article, aside, figure, footer, header, nav, section, details, summary {
  display: block;
}

/* Handle box-sizing while better addressing child elements:
   http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/* Responsive images and other embedded objects */
/* if you don't have full control over `img` tags (if you have to overcome attributes), consider adding height: auto */
img,
object,
embed {
  max-width: 100%;
}

/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
	In fact, it *will* cause problems with Google Maps' controls at small size.
	If this is the case for you, try uncommenting the following:

#map img {
		max-width: none;
}
*/
/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll;
}

/* we use a lot of ULs that aren't bulleted.
	you'll have to restore the bullets within content,
	which is fine because they're probably customized anyway */
ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

pre {
  white-space: pre; /* CSS2 */
  white-space: pre-wrap; /* CSS 2.1 */
  white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word; /* IE */
}

input[type=radio] {
  vertical-align: text-bottom;
}

input[type=checkbox] {
  vertical-align: bottom;
}

.ie7 input[type=checkbox] {
  vertical-align: baseline;
}

.ie6 input {
  vertical-align: text-bottom;
}

select, input, textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

td, td img {
  vertical-align: top;
}

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif;
}

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0;
}

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible;
}

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic;
}

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

html {
  font-size: 62.5%;
  height: auto;
  overflow: visible;
}

body {
  font-size: 1.6rem;
  background-color: rgba(0, 0, 0, 0.5);
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  color: #232323;
}
@media screen and (max-width: 768px) {
  body {
    font-size: 1.4rem;
  }
}
body.navOpened {
  overflow: hidden;
  height: 100%;
}

.container {
  margin: 0 auto;
  max-width: 100%;
  padding-left: 3vw;
  padding-right: 3vw;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

img {
  vertical-align: bottom;
}

@keyframes blur {
  0% {
    filter: blur(0px) grayscale(100%);
    transform: scale(1);
  }
  50% {
    filter: blur(8px) grayscale(50%);
    transform: scale(1.1);
  }
  100% {
    filter: blur(0px) grayscale(0%);
    transform: scale(1.05);
  }
}
.main {
  display: block;
  position: relative;
  z-index: 1;
}

.wrapper {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
}
.wrapper.navOpened .header__name, .wrapper.navOpened .header__logo, .wrapper.navOpened .topSlider, .wrapper.navOpened .mainWrapper, .wrapper.navOpened .footer {
  filter: blur(8px);
}
.wrapper .header__name, .wrapper .header__logo, .wrapper .topSlider, .wrapper .mainWrapper, .wrapper .footer {
  transition: filter 0.5s 0s ease;
}
@media screen and (max-width: 768px) {
  .wrapper {
    padding-bottom: 52.5px;
  }
}
.wrapper.isScrolled .header__inner {
  position: fixed;
  top: 0;
  width: 100%;
}

.pageBg {
  position: fixed;
  display: block;
  left: -8px;
  top: -8px;
  width: calc(100vw + 16px);
  height: calc(100vh + 16px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.5;
  filter: blur(8px);
  z-index: 0;
}
.pageBg.navOpened {
  z-index: 1;
}

.footer {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 60px 30px 0;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .footer {
    padding: 45px 20px 0;
  }
}
.footer__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer__logo {
  width: 240px;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .footer__logo {
    width: 210px;
    margin-bottom: 20px;
  }
}
.footer__info {
  max-width: 640px;
  margin: 0 auto 30px;
  color: #fff;
  font-size: 1.4rem;
  line-height: 1.75;
}
@media screen and (max-width: 768px) {
  .footer__info {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
}
.footer__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -10px;
}
@media screen and (max-width: 768px) {
  .footer__list {
    margin: 0;
    flex-direction: column;
  }
}
.footer__listItem {
  padding: 10px;
}
@media screen and (max-width: 768px) {
  .footer__listItem {
    width: 200px;
    margin-bottom: 10px;
    padding: 0;
  }
  .footer__listItem:last-of-type {
    margin-bottom: 0;
  }
}
.footer__listLink {
  padding: 10px 30px;
  background-color: #fff;
  display: block;
  border-radius: 60px;
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  position: relative;
}
@media screen and (max-width: 768px) {
  .footer__listLink {
    font-size: 1.2rem;
    padding: 10px 15px;
    width: 100%;
    justify-content: center;
  }
}
.footer__listLink:after {
  content: "";
  width: 6px;
  height: 6px;
  display: block;
  border-top: 1px solid #232323;
  border-right: 1px solid #232323;
  transform: rotate(45deg);
  margin-left: 10px;
}
@media screen and (max-width: 768px) {
  .footer__listLink:after {
    position: absolute;
    right: 15px;
    top: calc(50% - 3px);
  }
}
.footer__copy {
  display: block;
  margin-top: 45px;
  background-color: #000;
  color: #fff;
  text-align: center;
  margin-left: -30px;
  margin-right: -30px;
  font-size: 1.2rem;
  padding: 10px;
}
@media screen and (max-width: 768px) {
  .footer__copy {
    padding: 5px 10px;
    font-size: 1rem;
    margin-top: 25px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.footer__copyLink {
  color: #fff;
  text-decoration: none;
}
.footer__copyName {
  color: #fff;
  text-decoration: none;
}
.footer__nav {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .footer__nav {
    display: flex;
  }
}
.footer__navItem {
  width: 25%;
  flex-basis: 25%;
}
.footer__navItem:nth-of-type(odd) {
  background-color: #fff;
}
.footer__navItem:nth-of-type(even) {
  background-color: #f5f5f5;
}
.footer__navItem:nth-of-type(1) .footer__navLink:before, .footer__navItem:nth-of-type(2) .footer__navLink:before, .footer__navItem:nth-of-type(3) .footer__navLink:before {
  content: "";
  width: 19.2px;
  height: 15px;
  background: url(../images/icon_movie_black.svg) no-repeat center center/cover;
  display: block;
  margin-bottom: 0.25em;
}
.footer__navItem:nth-of-type(4) .footer__navLink:before {
  content: "";
  width: 19.2px;
  height: 15px;
  background: url(../images/icon_menu.svg) no-repeat center center/cover;
  display: block;
  margin-bottom: 0.25em;
}
.footer__navLink {
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  color: inherit;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  flex-direction: column;
  align-items: center;
}

.gNav {
  max-width: 540px;
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  padding: 60px 30px;
  transform: translateY(-200%);
  transition: transform 0.3s ease;
  max-height: 100vh;
  overflow: scroll;
}
@media screen and (max-width: 768px) {
  .gNav {
    max-width: 100%;
  }
}
.gNav__head {
  font-size: 2.4rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}
.gNav__item {
  border-bottom: 1px solid #232323;
}
.gNav__item.jsOpen .dropMenu {
  margin: -3px 0 10px 0;
}
.gNav__item.jsOpen .gNav__drop:before {
  display: none;
}
.gNav__link {
  color: inherit;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  width: 100%;
  text-decoration: none;
  padding: 15px 0;
}
.gNav__link:after {
  content: "";
  position: relative;
  width: 6px;
  height: 6px;
  border-top: 1px solid #232323;
  border-right: 1px solid #232323;
  transform: rotate(45deg);
  position: absolute;
  right: 2px;
  top: calc(50% - 3px);
}
.gNav .gNav__drop {
  position: relative;
  color: inherit;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  width: 100%;
  text-decoration: none;
  padding: 15px 0;
}
.gNav .gNav__drop:after {
  content: "";
  width: 12px;
  height: 1px;
  display: block;
  background-color: #232323;
  position: absolute;
  right: 0;
  top: calc(50% - 0.5px);
  transform: none;
}
.gNav .gNav__drop:before {
  content: "";
  width: 1px;
  height: 12px;
  position: absolute;
  right: 5.5px;
  top: 21.5px;
  background-color: #232323;
}
@media screen and (max-width: 768px) {
  .gNav .gNav__drop:before {
    top: 19.5px;
  }
}

.dropMenu {
  height: 0;
  overflow: hidden;
  transition: all 0.5s;
  display: flex;
  flex-wrap: wrap;
}
.dropMenu__item {
  padding: 3px;
}
.dropMenu__link {
  color: inherit;
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: #fff;
  padding: 5px 10px;
  font-size: 1.4rem;
}
@media screen and (max-width: 768px) {
  .dropMenu__link {
    font-size: 1.2rem;
  }
}

.cast__list_type_nav .cast__link {
  background-color: #fff;
  color: inherit;
}
.cast__list_type_nav .cast__link:hover {
  color: #fff;
}

.header {
  position: relative;
  z-index: 2;
  width: 100%;
  top: 0;
  left: 0;
}
.header__name {
  background-color: #000;
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  line-height: 1;
  padding: 10px 10px;
  overflow-x: auto;
  white-space: nowrap;
}
.header__inner {
  padding: 1.5vw 3vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .header__inner {
    padding: 15px 20px;
  }
}
.header__logo {
  width: 240px;
  flex-basis: 240px;
}
@media screen and (max-width: 768px) {
  .header__logo {
    width: 200px;
    flex-basis: 200px;
  }
}
.header.navOpened .hum__inner {
  transform: rotate(360deg);
}
.header.navOpened .hum__bar {
  background-color: #232323;
}
.header.navOpened .hum__bar:nth-of-type(1) {
  top: 1px;
  transform: translateY(9px) rotate(-45deg);
}
@media screen and (max-width: 768px) {
  .header.navOpened .hum__bar:nth-of-type(1) {
    transform: translateY(7px) rotate(-45deg);
  }
}
.header.navOpened .hum__bar:nth-of-type(2) {
  opacity: 0;
}
.header.navOpened .hum__bar:nth-of-type(3) {
  top: 100%;
  transform: translateY(-9px) rotate(45deg);
}
@media screen and (max-width: 768px) {
  .header.navOpened .hum__bar:nth-of-type(3) {
    transform: translateY(-7px) rotate(45deg);
  }
}
.header.navOpened .hum__text {
  color: #232323;
}
.header.navOpened .gNav {
  display: block;
  transform: translateY(0%);
  transition: transform 0.3s ease;
}

.hum {
  cursor: pointer;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hum__inner {
  width: 32px;
  height: 20px;
  position: relative;
  transition: all 0.6s;
}
@media screen and (max-width: 768px) {
  .hum__inner {
    width: 24px;
    height: 16px;
  }
}
.hum__bar {
  width: 100%;
  height: 1px;
  background-color: #fff;
  display: block;
  position: absolute;
  left: 0;
}
.hum__bar:nth-of-type(1) {
  top: 0;
}
.hum__bar:nth-of-type(2) {
  top: calc(50% - 0.5px);
}
.hum__bar:nth-of-type(3) {
  bottom: 0;
}
.hum__text {
  font-size: 1rem;
  color: #fff;
  text-align: center;
  margin-top: 5px;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .hum__text {
    transform: scale(0.8);
    transform-origin: center top;
  }
}

.mainWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 6vw;
}

.main {
  width: 77vw;
  flex-basis: 77vw;
  padding-right: 3vw;
}
@media screen and (max-width: 1260px) {
  .main {
    width: calc(100% - 240px);
    flex-basis: calc(100% - 240px);
  }
}
@media screen and (max-width: 768px) {
  .main {
    width: 100%;
    flex-basis: 100%;
    padding-right: 0;
    margin-bottom: 60px;
  }
}
.main_type_full {
  width: 100%;
  flex-basis: 100%;
  padding-right: 0;
}
@media screen and (max-width: 1260px) {
  .main_type_full {
    width: 100%;
    flex-basis: 100%;
  }
}

.searchForm__inner {
  position: relative;
  display: block;
}
.searchForm__text {
  width: 100%;
  background: transparent;
  border: none;
  border-bottom: 1px solid #232323;
  outline: none;
  appearance: none;
  border-radius: 0;
  padding: 10px 0;
  font-size: 1.6rem;
}
.searchForm__bt {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: transparent;
  background: url(../images/icon_search.svg) no-repeat center center/cover;
  outline: none;
  appearance: none;
  display: block;
  position: absolute;
  top: 12px;
  right: 0;
  border: none;
}

.sidebar {
  width: 17vw;
  flex-basis: 17vw;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 1260px) {
  .sidebar {
    width: 240px;
    flex-basis: 240px;
  }
}
@media screen and (max-width: 768px) {
  .sidebar {
    width: 100%;
    flex-basis: 100%;
  }
}

.siderbarContents {
  background-color: rgba(255, 255, 255, 0.3);
  margin-bottom: 3vw;
  padding: 15px 20px 20px 20px;
}
.siderbarContents:last-of-type {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .siderbarContents {
    margin-bottom: 20px;
  }
}
.siderbarContents__ttl {
  color: #fff;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0.75em;
  text-align: center;
}
.popArticles {
  counter-reset: listnum;
}
.popArticles__item {
  list-style: none;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 15px;
}
.popArticles__item:last-of-type {
  margin-bottom: 0;
}
.popArticles__item:before {
  counter-increment: listnum;
  content: counter(listnum);
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.2rem;
  width: 20px;
  height: 20px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popArticles__fig {
  width: 35%;
  flex-basis: 35%;
}
@media screen and (max-width: 768px) {
  .popArticles__fig {
    width: 100px;
    flex-basis: 100px;
  }
}
.popArticles__figLink {
  display: block;
  overflow: hidden;
}
.popArticles__figLink:hover .popArticles__figImg {
  animation-name: blur;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.popArticles__figImg {
  aspect-ratio: 1/1;
  object-fit: cover;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}
.popArticles__text {
  width: 65%;
  flex-basis: 65%;
  padding-left: 15px;
}
@media screen and (max-width: 768px) {
  .popArticles__text {
    width: calc(100% - 100px);
    flex-basis: calc(100% - 100px);
  }
}
.popArticles__ttlLink {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.4;
  text-decoration: none;
  color: #fff;
}
.popArticles__ttlLink:hover {
  color: #f09;
}

.cats {
  margin-bottom: 1.5vw;
}
@media screen and (max-width: 768px) {
  .cats {
    margin-bottom: 20px;
  }
}
.cats_type_kw .cats__link:before {
  content: "#";
  font-weight: 900;
  margin-right: 0.25em;
}
.cats_type_vod .cats__link:before {
  content: "";
  width: 18.85px;
  height: 13.75px;
  background: url(../images/icon_vod.svg) no-repeat center center/cover;
  display: block;
  margin-right: 0.25em;
  position: relative;
  top: 1px;
}
.cats_type_genre .cats__link:before {
  content: "";
  width: 19.2px;
  height: 15px;
  background: url(../images/icon_movie.svg) no-repeat center center/cover;
  display: block;
  margin-right: 0.25em;
  position: relative;
  top: 1px;
}
.cats:last-of-type {
  margin-bottom: 0;
}
.cats__ttl {
  margin-bottom: 0.5em;
}
.cats__ttlLink {
  font-size: 1.6rem;
  color: #fff;
  display: flex;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .cats__ttlLink {
    font-size: 1.4rem;
  }
}
.cats__ttlLink:before {
  content: "-";
  margin-right: 0.5em;
}
.cats__ttlLink:hover {
  text-decoration: underline;
}
.cats__list {
  display: flex;
  flex-wrap: wrap;
  margin: -3px;
}
.cats__item {
  padding: 3px;
}
.cats__link {
  padding: 5px 10px;
  font-size: 1.2rem;
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.cats__link:hover {
  background-color: #f09;
}

.cast__list {
  display: flex;
  flex-wrap: wrap;
  margin: -3px;
}
.cast__item {
  padding: 3px;
  width: 20%;
  flex-basis: 20%;
}
.cast__link {
  padding: 5px 10px;
  font-size: 1.2rem;
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cast__link:hover {
  background-color: #f09;
}

.articleList {
  display: flex;
  flex-wrap: wrap;
}
.articleList__item {
  background-color: rgba(255, 255, 255, 0.3);
  width: 33.333%;
  flex-basis: 33.333%;
  position: relative;
}
@media screen and (max-width: 1260px) {
  .articleList__item {
    width: 50%;
    flex-basis: 50%;
  }
}
@media screen and (max-width: 1050px) {
  .articleList__item {
    width: 100%;
    flex-basis: 100%;
  }
}
@media screen and (max-width: 768px) {
  .articleList__item {
    margin-bottom: 20px;
  }
  .articleList__item:last-of-type {
    margin-bottom: 0;
  }
}
.articleList__item:nth-of-type(1), .articleList__item:nth-of-type(2), .articleList__item:nth-of-type(6), .articleList__item:nth-of-type(7), .articleList__item:nth-of-type(11), .articleList__item:nth-of-type(12), .articleList__item:nth-of-type(16), .articleList__item:nth-of-type(17) {
  width: 50%;
  flex-basis: 50%;
}
@media screen and (max-width: 1050px) {
  .articleList__item:nth-of-type(1), .articleList__item:nth-of-type(2), .articleList__item:nth-of-type(6), .articleList__item:nth-of-type(7), .articleList__item:nth-of-type(11), .articleList__item:nth-of-type(12), .articleList__item:nth-of-type(16), .articleList__item:nth-of-type(17) {
    width: 100%;
    flex-basis: 100%;
  }
}
.articleList__item:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.5);
}
.articleList__fig {
  overflow: hidden;
}
.articleList__figLink {
  overflow: hidden;
  display: block;
}
.articleList__figLink:after {
  padding-bottom: 100%;
}
.articleList__figLink:hover .articleList__img {
  animation-name: blur;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.articleList__img {
  aspect-ratio: 16/10;
  object-fit: cover;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
  width: 100%;
  height: auto;
}
.articleList__vod {
  display: flex;
  margin-top: -32px;
  position: relative;
  z-index: 1;
}
.articleList__vodItem {
  width: 25%;
  flex-basis: 25%;
}
.articleList__vodLink {
  background-color: #000;
  color: #fff;
  font-size: 1.2rem;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 32px;
  position: relative;
}
.articleList__vodLink:hover {
  top: -2px;
}
@media screen and (max-width: 1260px) {
  .articleList__vodLink {
    font-size: 1rem;
  }
}
.articleList__vodLink_type_amazonprime {
  background-color: #44A2C6;
}
.articleList__vodLink_type_hulu {
  background-color: #80c04b;
}
.articleList__vodLink_type_unext {
  background-color: #00A6BA;
}
.articleList__text {
  padding: 20px 20px 50px;
  display: flex;
  flex-direction: column;
}
.articleList__cats {
  display: flex;
  flex-wrap: wrap;
  margin: -3px;
}
.articleList__catsItem {
  padding: 3px;
}
.articleList__catsLink {
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  text-decoration: none;
  padding: 3px 10px;
  font-size: 1.2rem;
}
@media screen and (max-width: 768px) {
  .articleList__catsLink {
    font-size: 1rem;
  }
}
.articleList__catsLink:hover {
  background-color: #ff0099;
  color: #fff;
}
.articleList__date {
  color: #fff;
  font-size: 1.4rem;
  margin-top: 15px;
  display: block;
}
@media screen and (max-width: 768px) {
  .articleList__date {
    font-size: 1.2rem;
    margin-top: 10px;
  }
}
.articleList__ttl {
  margin-top: 5px;
  margin-bottom: 10px;
}
.articleList__ttlLink {
  font-size: 2.1rem;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .articleList__ttlLink {
    font-size: 1.8rem;
    margin-bottom: 5px;
  }
}
.articleList__ttlLink:hover {
  color: #ff0099;
}
.articleList__excerpt {
  font-size: 1.2rem;
  color: #fff;
  line-height: 1.75;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.articleList__recommend {
  display: flex;
  position: absolute;
  bottom: 20px;
}
.articleList__recommendDt {
  font-size: 1.6rem;
  display: flex;
  font-weight: 700;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .articleList__recommendDt {
    font-size: 1.4rem;
  }
}
.articleList__recommendDt:after {
  content: ":";
  padding: 0 0.5em;
  font-weight: 700;
  color: #fff;
}
.articleList__recommendDd {
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  position: relative;
  z-index: 0;
  display: inline-block;
  white-space: nowrap;
  color: #ababab;
}
@media screen and (max-width: 768px) {
  .articleList__recommendDd {
    font-size: 1.4rem;
  }
}
.articleList__recommendDd:before, .articleList__recommendDd:after {
  content: "★★★★★";
}
.articleList__recommendDd:after {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
  color: #ffe54a;
}
.articleList__recommendDd[data-rate="5"]:after {
  width: 100%;
}
.articleList__recommendDd[data-rate="4.5"]:after {
  width: 90%;
}
.articleList__recommendDd[data-rate="4"]:after {
  width: 80%;
}
.articleList__recommendDd[data-rate="3.5"]:after {
  width: 70%;
}
.articleList__recommendDd[data-rate="3"]:after {
  width: 60%;
}
.articleList__recommendDd[data-rate="2.5"]:after {
  width: 50%;
}
.articleList__recommendDd[data-rate="2"]:after {
  width: 40%;
}
.articleList__recommendDd[data-rate="1.5"]:after {
  width: 30%;
}
.articleList__recommendDd[data-rate="1.0"]:after {
  width: 20%;
}
.articleList__recommendDd[data-rate="0.5"]:after {
  width: 10%;
}

.moreBt {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  max-width: 100%;
  height: 60px;
  margin: 0 auto 0;
  border: 1px solid rgba(0, 0, 0, 0.9);
  text-decoration: none;
}
.moreBt:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.moreBt:after {
  content: "";
  width: 6px;
  height: 6px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg);
  display: block;
  position: absolute;
  right: 20px;
  top: calc(50% - 3px);
}

.pagination__list {
  display: flex;
  margin: 40px -5px 0 -5px;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .pagination__list {
    margin-top: 30px;
  }
}
.pagination__item {
  list-style: none;
  padding: 0 5px;
}
.pagination__item_type_prev .pagination__link:after {
  content: "";
  width: 6px;
  height: 6px;
  display: block;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  transform: rotate(-45deg);
}
.pagination__item_type_prev .pagination__link_current {
  background-color: #696969;
  color: #ccc;
}
.pagination__link {
  display: flex;
  width: 42px;
  height: 42px;
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  text-decoration: none;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .pagination__link {
    width: 36px;
    height: 36px;
  }
}
.pagination__link:hover {
  background-color: #f09;
}
.pagination__link_current {
  pointer-events: none;
  background-color: #f09;
}
.pagination__link_isCurrent {
  pointer-events: none;
}
.pagination__item_type_next .pagination__link:after {
  content: "";
  width: 6px;
  height: 6px;
  display: block;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg);
}
.pagination__item_type_next .pagination__link_current {
  background-color: #696969;
  color: #ccc;
}

.sectionTtl {
  color: #fff;
  font-size: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .sectionTtl {
    font-size: 2.1rem;
  }
}
.sectionTtl:before {
  content: "";
  display: block;
  background: url(../images/icon_main_ttl.svg) no-repeat center center/cover;
  width: 24px;
  height: 21.3px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
@media screen and (max-width: 768px) {
  .sectionTtl:before {
    width: 21px;
    height: 18px;
    top: 1px;
  }
}

/* stylelint-disable declaration-colon-space-after */
.main {
  width: 100%;
}

.categoryInfo {
  margin-top: 30px;
  margin-bottom: 45px;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .categoryInfo {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
.categoryInfo__ttl {
  margin-bottom: 1em;
}
.categoryInfo__contents {
  display: flex;
  flex-wrap: wrap;
}
.categoryInfo__fig {
  width: 50%;
  flex-basis: 50%;
}
@media screen and (max-width: 768px) {
  .categoryInfo__fig {
    width: 100vw;
    flex-basis: 100vw;
  }
}
.categoryInfo__img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  width: 100%;
}
.categoryInfo__text {
  width: 50%;
  flex-basis: 50%;
  display: flex;
  align-items: center;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 768px) {
  .categoryInfo__text {
    width: 100vw;
    flex-basis: 100vw;
    max-width: 100vw;
    padding: 20px 25px;
  }
}
.categoryInfo__desc {
  color: #fff;
  line-height: 2;
}

.actors__list .articleList__item:nth-of-type(odd) .articleList__figLink {
  position: relative;
  z-index: 1;
}
.actors__list .articleList__item:nth-of-type(odd) .articleList__figLink:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.05);
}

/* stylelint-disable declaration-colon-space-after */
.contact .single__body {
  padding-top: 45px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.contact__dl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #232323;
}
@media screen and (max-width: 768px) {
  .contact__dl {
    padding: 15px 0;
  }
}
.contact__dt {
  width: 220px;
  flex-basis: 220px;
  font-weight: 500;
  padding-right: 30px;
  display: flex;
}
@media screen and (max-width: 768px) {
  .contact__dt {
    width: 100%;
    flex-basis: 100%;
    padding-right: 0;
    margin-bottom: 5px;
  }
}
.contact__dt:after {
  content: "＊";
  color: #db0000;
  font-weight: 900;
  margin-left: 0.5em;
}
.contact__dd {
  width: calc(100% - 220px);
  flex-basis: calc(100% - 220px);
}
@media screen and (max-width: 768px) {
  .contact__dd {
    width: 100%;
    flex-basis: 100%;
  }
}
.contact__select {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #eee;
  appearance: none;
  outline: none;
  background: url(../images/icon_select.svg) right 10px center no-repeat;
  background-size: 7px;
  background-color: #f9f9f9;
}
.contact__input {
  width: 100%;
  background-color: #f9f9f9;
  padding: 10px 15px;
  border: 1px solid #eee;
  appearance: none;
  outline: none;
}
.contact__body {
  width: 100%;
  background-color: #f9f9f9;
  padding: 10px 15px;
  border: 1px solid #eee;
  height: 270px;
  appearance: none;
  outline: none;
}
.contact__checkbox {
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  vertical-align: -5px;
  appearance: none;
}
.contact__checkbox:checked:before {
  position: absolute;
  top: 0px;
  left: 5px;
  transform: rotate(50deg);
  width: 4px;
  height: 12px;
  border-right: 1px solid #db0000;
  border-bottom: 1px solid #db0000;
  content: "";
}
.contact__bts {
  display: flex;
  justify-content: center;
}
.contact__btsItem {
  padding: 0 10px;
}
.contact__bt {
  background-color: #232323;
  color: #fff;
  border: 1px solid #232323;
  padding: 20px 30px;
  font-size: 1.6rem;
  width: 300px;
}
@media screen and (max-width: 768px) {
  .contact__bt {
    width: 100%;
    padding: 15px 20px;
  }
}
.contact .contact__btsItem_type_submit button {
  background-color: #232323;
  color: #fff;
  border: 1px solid #232323;
  padding: 20px 30px;
  font-size: 1.6rem;
  width: 300px;
}
@media screen and (max-width: 768px) {
  .contact .contact__btsItem_type_submit button {
    width: 100%;
  }
}
.contact__btsItem_type_back {
  display: none;
}
.contact__btsItem_type_submit {
  display: none;
}

.mwform-checkbox-field {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.mwform-checkbox-field label {
  display: flex;
  align-items: center;
}

.mw_wp_form_confirm .contact__dl:last-of-type {
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .mw_wp_form_confirm .contact__dl:last-of-type {
    margin-bottom: 15px;
  }
}
.mw_wp_form_confirm .contact__bt_type_back {
  background-color: #eee;
  color: #ababab;
  border-color: #ababab;
}
.mw_wp_form_confirm .contact__btsItem_type_confirm {
  display: none;
}
.mw_wp_form_confirm .contact__btsItem_type_back {
  display: block;
}
.mw_wp_form_confirm .contact__btsItem_type_submit {
  display: block;
}
.mw_wp_form_confirm .contact__btsItem {
  max-width: 320px;
  width: 50%;
}

/* stylelint-disable declaration-colon-space-after */
.topSlider {
  margin-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .topSlider {
    margin-bottom: 45px;
  }
}
.topSlider__item {
  width: 840px;
  flex-basis: 840px;
}
@media screen and (max-width: 768px) {
  .topSlider__item {
    width: calc(100vw - 40px);
    flex-basis: calc(100vw - 40px);
  }
}
.topSlider .swiper-slide-active .topSlider__figImg {
  filter: grayscale(0%);
}
.topSlider__figLink {
  display: block;
  overflow: hidden;
}
.topSlider__figLink:hover .topSlider__figImg {
  animation-name: blur;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.topSlider__figImg {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}
.topSlider__text {
  position: relative;
  z-index: 2;
  left: -15px;
  top: -60px;
  margin-bottom: -45px;
  width: calc(100% - 90px);
}
@media screen and (max-width: 768px) {
  .topSlider__text {
    left: -10px;
    top: -30px;
    margin-bottom: -30px;
    width: calc(100% - 45px);
  }
}
.topSlider__cats {
  display: flex;
  margin: 0 -1px;
}
.topSlider__catsItem {
  padding: 0 1px;
}
.topSlider__catsLink {
  display: block;
  background-color: white;
  color: inherit;
  font-size: 1.4rem;
  text-decoration: none;
  padding: 5px 15px;
  line-height: 1;
  transition: color, background-color 0.3s ease;
  color: #232323;
}
@media screen and (max-width: 768px) {
  .topSlider__catsLink {
    font-size: 1rem;
  }
}
.topSlider__catsLink:hover {
  background-color: #000;
  color: #fff;
}
.topSlider__ttlLink {
  color: inherit;
  text-decoration: none;
  background-color: white;
  color: #232323;
  font-size: 2.1rem;
  line-height: 1.75;
  text-decoration: none;
  font-weight: 400;
  display: inline-block;
  margin-top: 2px;
  padding: 0px 10px;
  transition: color, background-color 0.3s ease;
}
.topSlider__ttlLink:hover {
  background-color: #000;
  color: #fff;
}
.column {
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .column {
    margin-top: 45px;
  }
}

/* stylelint-disable declaration-colon-space-after */
/* stylelint-disable declaration-colon-space-after */
.breadcrumb {
  background-color: #000;
  padding: 12px 21px;
  width: 100%;
  margin-bottom: 3vw;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 768px) {
  .breadcrumb {
    padding: 10px 15px;
    margin-bottom: 20px;
  }
}
.breadcrumb__list {
  list-style: none;
  display: flex;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  flex-wrap: nowrap;
}
.breadcrumb__item {
  display: flex;
  align-items: center;
}
.breadcrumb__item:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid #ffffff;
  border-right: 0;
  margin: 0 1em;
}
.breadcrumb__item:last-of-type:after {
  display: none;
}
.breadcrumb__link {
  color: #fff;
  text-decoration: none;
  font-size: 1.4rem;
}
@media screen and (max-width: 768px) {
  .breadcrumb__link {
    font-size: 1.2rem;
  }
}
.single__contents {
  background-color: #fff;
  padding: 30px 30px 60px 30px;
}
@media screen and (max-width: 768px) {
  .single__contents {
    padding: 20px;
  }
}
.single__header {
  margin: -30px -30px 1.8vw -30px;
  background-color: #000;
  padding: 15px 30px;
}
@media screen and (max-width: 768px) {
  .single__header {
    padding: 0;
    margin: 0 0 15px 0;
    background: transparent;
  }
}
.single__ttl {
  font-size: 2.7rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.75;
}
@media screen and (max-width: 768px) {
  .single__ttl {
    font-size: 2.1rem;
    color: #232323;
  }
}
.single__cats {
  display: flex;
  margin: 0 -5px 1.8vw;
}
@media screen and (max-width: 768px) {
  .single__cats {
    margin-bottom: 20px;
  }
}
.single__catsItem {
  padding: 0 5px;
}
.single__catsLink {
  border: 1px solid #232323;
  padding: 5px 15px;
  color: #232323;
  text-decoration: none;
  display: block;
}
@media screen and (max-width: 768px) {
  .single__catsLink {
    font-size: 1.2rem;
    padding: 3px 10px;
  }
}
.single__catsLink:hover {
  background-color: #232323;
  color: #fff;
}
.single__eyecatch {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 3vw;
}
@media screen and (max-width: 768px) {
  .single__eyecatch {
    margin-bottom: 30px;
  }
}
.single__eyecatchCopy {
  font-size: 1.4rem;
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .single__eyecatchCopy {
    font-size: 1rem;
    margin-top: 5px;
  }
}
.single__copy {
  border-left: 2px solid #00b7ff;
  border-right: 2px solid #f09;
  padding: 20px 25px;
  position: relative;
  font-size: 2.1rem;
  color: #f09;
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin-bottom: 3vw;
}
@media screen and (max-width: 768px) {
  .single__copy {
    padding: 10px 15px;
    margin-bottom: 30px;
    font-size: 1.8rem;
  }
}
.single__copy:before {
  content: "";
  height: 2px;
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to left, #ff0099, #00b7ff);
}
.single__copy:after {
  content: "";
  height: 2px;
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(to left, #ff0099, #00b7ff);
}
.single__recommend {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.1vw;
}
@media screen and (max-width: 768px) {
  .single__recommend {
    margin-bottom: 20px;
  }
}
.single__recommendDt {
  width: 200px;
  padding: 20px;
  font-weight: 600;
  font-size: 1.8rem;
}
.single__recommendDt:nth-of-type(odd) {
  background-color: #f2f2f2;
}
@media screen and (max-width: 768px) {
  .single__recommendDt {
    padding: 15px 15px;
    width: 120px;
    flex-basis: 120px;
    font-size: 1.6rem;
  }
}
.single__recommendDd {
  width: calc(100% - 200px);
  padding: 20px;
}
@media screen and (max-width: 768px) {
  .single__recommendDd {
    width: calc(100% - 120px);
    flex-basis: calc(100% - 120px);
    padding: 15px 15px;
  }
}
.single__recommendDd:nth-of-type(odd) {
  background-color: #f2f2f2;
}
.single__recommendDd .single__recommendDdStar {
  position: relative;
  font-size: 1.8rem;
  font-weight: 500;
  position: relative;
  display: inline-block;
  color: #ababab;
}
@media screen and (max-width: 768px) {
  .single__recommendDd .single__recommendDdStar {
    font-size: 1.6rem;
  }
}
.single__recommendDd .single__recommendDdStar:before, .single__recommendDd .single__recommendDdStar:after {
  content: "★★★★★";
}
.single__recommendDd .single__recommendDdStar:after {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
  color: #f09;
}
.single__recommendDd[data-rate="5"] .single__recommendDdStar:after {
  width: 100%;
}
.single__recommendDd[data-rate="4.5"] .single__recommendDdStar:after {
  width: 90%;
}
.single__recommendDd[data-rate="4"] .single__recommendDdStar:after {
  width: 80%;
}
.single__recommendDd[data-rate="3.5"] .single__recommendDdStar:after {
  width: 70%;
}
.single__recommendDd[data-rate="3"] .single__recommendDdStar:after {
  width: 60%;
}
.single__recommendDd[data-rate="2.5"] .single__recommendDdStar:after {
  width: 50%;
}
.single__recommendDd[data-rate="2"] .single__recommendDdStar:after {
  width: 40%;
}
.single__recommendDd[data-rate="1.5"] .single__recommendDdStar:after {
  width: 30%;
}
.single__recommendDd[data-rate="1.0"] .single__recommendDdStar:after {
  width: 20%;
}
.single__recommendDd[data-rate="0.5"] .single__recommendDdStar:after {
  width: 10%;
}
.single__vods {
  display: flex;
  align-items: center;
  margin-bottom: 2.1vw;
}
@media screen and (max-width: 768px) {
  .single__vods {
    flex-wrap: wrap;
    margin: 20px 0;
  }
}
.single__vodsItem {
  width: 50%;
  flex-basis: 50%;
}
@media screen and (max-width: 768px) {
  .single__vodsItem {
    width: 100%;
    flex-basis: 100%;
  }
  .single__vodsItem:first-of-type {
    margin-bottom: 5px;
  }
}
.single__vodsItem a {
  color: #fff;
  padding: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.single__vodsItem a:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
  transform: rotate(45deg);
  margin-left: 0.5em;
}
.single__vodsItem:first-of-type a {
  background-color: #44A2C6;
  border: 1px solid #44A2C6;
}
.single__vodsItem:first-of-type a:hover {
  background-color: transparent;
  color: #44A2C6;
}
.single__vodsItem:first-of-type a:hover:after {
  border-color: #44A2C6;
}
.single__vodsItem:last-of-type a {
  background-color: #00A6BA;
  border: 1px solid #00A6BA;
}
.single__vodsItem:last-of-type a:hover {
  color: #00A6BA;
  background-color: transparent;
}
.single__vodsItem:last-of-type a:hover:after {
  border-color: #00A6BA;
}
.single__vodsLink {
  color: #fff;
  padding: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.single__vodsLink:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
  transform: rotate(45deg);
  margin-left: 0.5em;
}
.single__vodsLink_type_unext {
  background-color: #44A2C6;
  border: 1px solid #44A2C6;
}
.single__vodsLink_type_unext:hover {
  background-color: transparent;
  color: #44A2C6;
}
.single__vodsLink_type_unext:hover:after {
  border-color: #44A2C6;
}
.single__vodsLink_type_prime {
  background-color: #00A6BA;
  border: 1px solid #00A6BA;
}
.single__vodsLink_type_prime:hover {
  color: #00A6BA;
  background-color: transparent;
}
.single__vodsLink_type_prime:hover:after {
  border-color: #00A6BA;
}
.single__section {
  margin-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .single__section {
    margin-bottom: 45px;
  }
}
.single__section:last-of-type {
  margin-bottom: 0;
}
.single__sectionTtl {
  font-size: 2.4rem;
  color: #232323;
  display: flex;
  align-items: center;
  font-weight: 500;
  border-bottom: 1px solid #232323;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .single__sectionTtl {
    font-size: 1.8rem;
    padding-bottom: 10px;
    margin-bottom: 25px;
  }
}
.single__sectionTtl:before {
  content: "";
  display: block;
  width: 22.4px;
  height: 19.11px;
  min-width: 22.4px;
  background: url(../images/icon_headline_02.svg) no-repeat center center/cover;
  margin-right: 0.5em;
}
.single__sectionBody h3 {
  font-size: 2.4rem;
  color: #232323;
  display: flex;
  align-items: center;
  font-weight: 500;
  border-bottom: 1px solid #232323;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .single__sectionBody h3 {
    font-size: 1.8rem;
    padding-bottom: 10px;
    margin-bottom: 25px;
  }
}
.single__sectionBody h3:before {
  content: "";
  display: block;
  width: 22.4px;
  height: 19.11px;
  min-width: 22.4px;
  background: url(../images/icon_headline_02.svg) no-repeat center center/cover;
  margin-right: 0.5em;
}
.single__sectionBody h4 {
  font-size: 1.2em;
  font-weight: 600;
  color: #f09;
  margin-bottom: 1em;
}
.single__sectionBody p {
  line-height: 1.75;
  margin-bottom: 1.5em;
}
.single__sectionBody img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}
.single__sectionBody iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}
.single__sectionBody a {
  color: inherit;
  text-decoration: underline;
}
.single__sectionBody a:hover {
  text-decoration: none;
}
.single__sectionBody .google-auto-placed {
  margin: 1.5em 0;
}
.single__sectionBody figure {
  margin: 1.5em 0;
}

.tableOfContents {
  border: 1px solid #232323;
  padding: 30px 30px;
  margin-bottom: 3.6vw;
}
@media screen and (max-width: 768px) {
  .tableOfContents {
    padding: 20px;
    margin-bottom: 30px;
  }
}
.tableOfContents__ttl {
  font-size: 1.8rem;
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #232323;
}
@media screen and (max-width: 768px) {
  .tableOfContents__ttl {
    font-size: 1.6rem;
  }
}
.tableOfContents__list {
  list-style: none;
}
.tableOfContents__item {
  border-bottom: 1px solid #ddd;
}
.tableOfContents__item:last-of-type {
  border-bottom: none;
}
.tableOfContents__item:last-of-type .tableOfContents__link {
  padding-bottom: 0;
}
.tableOfContents__link {
  line-height: 2;
  text-decoration: none;
  color: #232323;
  padding: 10px 10px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
}
@media screen and (max-width: 768px) {
  .tableOfContents__link {
    font-size: 1.2rem;
    padding: 10px 10px 10px 0;
    line-height: 1.5;
  }
}
.tableOfContents__link:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-top: 1px solid #232323;
  border-right: 1px solid #232323;
  transform: rotate(135deg);
}
.tableOfContents__link:hover {
  text-decoration: underline;
}

.singleYoutube iframe {
  aspect-ratio: 16/9;
  width: 100%;
  height: auto;
}

.wp-caption {
  width: auto !important;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .wp-caption {
    margin-bottom: 20px;
  }
}

.wp-caption-text {
  font-size: 1.4rem;
  margin-top: 10px;
  margin-bottom: 0 !important;
}
@media screen and (max-width: 768px) {
  .wp-caption-text {
    font-size: 1rem;
    margin-top: 5px;
  }
}

.singleOthers__section {
  padding: 20px;
}
@media screen and (max-width: 768px) {
  .singleOthers__section {
    padding: 10px 15px;
  }
}
.singleOthers__section:nth-of-type(odd) {
  background-color: #f2f2f2;
}
.singleOthers__sectionTtl {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0.5em;
}
@media screen and (max-width: 768px) {
  .singleOthers__sectionTtl {
    font-size: 1.6rem;
    margin-bottom: 0.25em;
  }
}
.singleOthers__sectionList li {
  display: flex;
  line-height: 2;
}
.singleOthers__sectionList li:before {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #232323;
  margin-right: 0.5em;
  position: relative;
  top: 15px;
}
@media screen and (max-width: 768px) {
  .singleOthers__sectionList li:before {
    top: 12px;
  }
}
.singleOthers__sectionLink {
  color: inherit;
  text-decoration: underline;
}

.vodList {
  display: flex;
}
.vodList__item {
  width: 25%;
  flex-basis: 25%;
}
.vodList__link {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  justify-content: center;
  background-color: #f2f2f2;
  color: #dddddd;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .vodList__link {
    font-size: 1rem;
    letter-spacing: -0.05em;
  }
}
.vodList__link_type_netflix {
  background-color: #000;
  color: #fff;
}
.vodList__link_type_amazonprime {
  background-color: #44A2C6;
  color: #fff;
}
.vodList__link_type_hulu {
  background-color: #80c04b;
  color: #fff;
}
.vodList__link_type_unext {
  background-color: #00A6BA;
  color: #fff;
}

.relatedArticles__ttl {
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 2.4rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
@media screen and (max-width: 768px) {
  .relatedArticles__ttl {
    font-size: 2.1rem;
  }
}
.relatedArticles__ttl:before {
  content: "";
  display: block;
  background: url(../images/icon_main_ttl.svg) no-repeat center center/cover;
  width: 24px;
  height: 21.3px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
@media screen and (max-width: 768px) {
  .relatedArticles__ttl:before {
    width: 21px;
    height: 18px;
    top: 1px;
  }
}
.relatedArticles__list .articleList__item {
  width: 33.333%;
  flex-basis: 33.333%;
}
@media screen and (max-width: 1260px) {
  .relatedArticles__list .articleList__item {
    width: 50%;
    flex-basis: 50%;
  }
}
@media screen and (max-width: 1050px) {
  .relatedArticles__list .articleList__item {
    width: 100%;
    flex-basis: 100%;
  }
}
@media screen and (max-width: 768px) {
  .relatedArticles__list .articleList__item {
    margin-bottom: 20px;
  }
  .relatedArticles__list .articleList__item:last-of-type {
    margin-bottom: 0;
  }
}

.single__hash {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
}
.single__hashItem {
  padding: 0.25em 0.5em;
  display: flex;
  align-items: center;
}
.single__hashItem:before {
  content: "#";
  font-weight: 900;
  margin-right: 0.25em;
}
.single__hashLink {
  color: inherit;
  text-decoration: none;
  display: flex;
}
.single__hashLink:hover {
  text-decoration: underline;
}

.u_d_n {
  display: none;
}
.u_d_b {
  display: block;
}
.u_d_f {
  display: flex;
}
@media screen and (max-width: 321px) {
  .u_se_d_n {
    display: none;
  }
  .u_se_d_b {
    display: block;
  }
  .u_se_d_f {
    display: flex;
  }
}
@media screen and (max-width: 768px) {
  .u_sp_d_n {
    display: none;
  }
  .u_sp_d_b {
    display: block;
  }
  .u_sp_d_f {
    display: flex;
  }
}
@media screen and (max-width: 1050px) {
  .u_tab_d_n {
    display: none;
  }
  .u_tab_d_b {
    display: block;
  }
  .u_tab_d_f {
    display: flex;
  }
}
@media screen and (max-width: 1400px) {
  .u_pc_d_n {
    display: none;
  }
  .u_pc_d_b {
    display: block;
  }
  .u_pc_d_f {
    display: flex;
  }
}
.u_ta_l {
  text-align: left;
}
.u_ta_c {
  text-align: center;
}
.u_ta_r {
  text-align: right;
}
@media screen and (max-width: 768px) {
  .u_sp_ta_l {
    text-align: left;
  }
  .u_sp_ta_c {
    text-align: center;
  }
  .u_sp_ta_r {
    text-align: right;
  }
}
@media screen and (max-width: 1050px) {
  .u_tab_ta_l {
    text-align: left;
  }
  .u_tab_ta_c {
    text-align: center;
  }
  .u_tab_ta_r {
    text-align: right;
  }
}
@media screen and (max-width: 1400px) {
  .u_pc_ta_l {
    text-align: left;
  }
  .u_pc_ta_c {
    text-align: center;
  }
  .u_pc_ta_r {
    text-align: right;
  }
}
.u_jc_fs {
  justify-content: flex-start;
}
.u_jc_c {
  justify-content: center;
}
.u_jc_fe {
  justify-content: flex-end;
}
.u_jc_sb {
  justify-content: space-between;
}
.u_jc_sa {
  justify-content: space-around;
}
@media screen and (max-width: 768px) {
  .u_sp_jc_fs {
    justify-content: flex-start;
  }
  .u_sp_jc_c {
    justify-content: center;
  }
  .u_sp_jc_fe {
    justify-content: flex-end;
  }
  .u_sp_jc_sb {
    justify-content: space-between;
  }
  .u_sp_jc_sa {
    justify-content: space-around;
  }
}
@media screen and (max-width: 1050px) {
  .u_tab_jc_fs {
    justify-content: flex-start;
  }
  .u_tab_jc_c {
    justify-content: center;
  }
  .u_tab_jc_fe {
    justify-content: flex-end;
  }
  .u_tab_jc_sb {
    justify-content: space-between;
  }
  .u_tab_jc_sa {
    justify-content: space-around;
  }
}
@media screen and (max-width: 1400px) {
  .u_pc_jc_fs {
    justify-content: flex-start;
  }
  .u_pc_jc_c {
    justify-content: center;
  }
  .u_pc_jc_fe {
    justify-content: flex-end;
  }
  .u_pc_jc_sb {
    justify-content: space-between;
  }
  .u_pc_jc_sa {
    justify-content: space-around;
  }
}