@use '../global' as *;
////////////////////////////////////////
/// header
////////////////////////////////////////
.header {
    position: relative;
    z-index: 2;
    width: 100%;
    top: 0;
    left: 0;
    &__name {
        background-color: #000;
        color: #fff;
        font-size: 1.2rem;
        text-align: center;
        line-height: 1;
        padding: 10px 10px;
        overflow-x: auto;
        white-space: nowrap;
    }

    &__inner {
        // padding: 20px 30px;
        padding: 1.5vw 3vw;
        // padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media('sp'){
            padding: 15px 20px;
        }
    }

    &__logo {
        width: 240px;
        flex-basis: 240px;
        @include media('sp'){
            width: 200px;
            flex-basis: 200px;
        }
    }

    &__logoLink {}

    &__nav {}
    &.navOpened {
        .hum{
            &__inner{
                transform: rotate(360deg);
            }
            &__bar{
                background-color: #232323;
                &:nth-of-type(1){
                    top: 1px;
                    transform: translateY(9px) rotate(-45deg);
                    @include media(){
                        transform: translateY(7px) rotate(-45deg);
                    }
                }
                &:nth-of-type(2){
                    opacity: 0;
                }
                &:nth-of-type(3){
                    top: 100%;
                    transform: translateY(-9px) rotate(45deg);
                    @include media(){
                        transform: translateY(-7px) rotate(45deg);
                    }
                }
            }
            &__text{
                color: #232323;
            }
        }
        .gNav{
            display: block;
            transform: translateY(0%);
            transition: transform .3s ease;
        }
    }
}

.dropMenu {
    &__item {}

    &__link {}
}

.hum {
    cursor: pointer;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__inner{
        width: 32px;
        height: 20px;
        position: relative;
        transition: all .6s;
        @include media('sp'){
            width: 24px;
            height: 16px;
        }
    }
    &__bar{
        width: 100%;
        height: 1px;
        background-color: #fff;
        display: block;
        position: absolute;
        left: 0;
        &:nth-of-type(1){
            top: 0;
        }
        &:nth-of-type(2){
            top: calc(50% - .5px);
        }
        &:nth-of-type(3){
            bottom: 0;
        }
    }
    &__text{
        font-size: 1.0rem;
        color: #fff;
        text-align: center;
        margin-top: 5px;
        line-height: 1;
        @include media(){
            transform: scale(0.8); transform-origin: center top; 
        }
    }
}