/* stylelint-disable declaration-colon-space-after */
@use '../global' as *;
//////////////////////////////
// contact
//////////////////////////////
.contact {
    .single__body{
        padding-top: 45px;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }
    &__intro {}

    &__dl {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #232323;
        @include media(){
            padding: 15px 0;
        }
    }

    &__dt {
        width: 220px;
        flex-basis: 220px;
        font-weight: 500;
        padding-right: 30px;
        display: flex;
        @include media(){
            width: 100%;
            flex-basis: 100%;
            padding-right: 0;
            margin-bottom: 5px;
        }
        &:after{
            content: "＊";
            color: #db0000;
            font-weight: 900;
            margin-left: .5em;
        }
    }

    &__dd {
        width: calc(100% - 220px);
        flex-basis: calc(100% - 220px);
        @include media(){
            width: 100%;
            flex-basis: 100%;
        }
    }

    &__select {
        width: 100%;
        padding: 10px 15px;
        border: 1px solid #eee;
        appearance: none;
        outline: none;
        background: url(../images/icon_select.svg) right 10px center no-repeat;
        background-size: 7px;
        background-color: #f9f9f9;
    }

    &__input {
        width: 100%;
        background-color: #f9f9f9;
        padding: 10px 15px;
        border: 1px solid #eee;
        appearance: none;
        outline: none;
    }

    &__body {
        width: 100%;
        background-color: #f9f9f9;
        padding: 10px 15px;
        border: 1px solid #eee;
        height: 270px;
        appearance: none;
        outline: none;
    }

    &__checkbox {
        position: relative;
        width: 16px;
        height: 16px;
        border: 1px solid #000;
        vertical-align: -5px;
        appearance: none;
        &:checked:before{
            position: absolute;
            top: 0px;
            left: 5px;
            transform: rotate(50deg);
            width: 4px;
            height: 12px;
            border-right: 1px solid #db0000;
            border-bottom: 1px solid #db0000;
            content: '';
        }
    }

    &__bts {
        display: flex;
        justify-content: center;
    }

    &__btsItem {
        padding: 0 10px;
    }

    &__btsItem_type_confirm {}

    &__bt {
        background-color: #232323;
        color: #fff;
        border: 1px solid #232323;
        padding: 20px 30px;
        font-size: 1.6rem;
        width: 300px;
        @include media(){
            width: 100%;
            padding: 15px 20px;
        }
    }

    .contact__btsItem_type_submit{
         button{
            background-color: #232323;
            color: #fff;
            border: 1px solid #232323;
            padding: 20px 30px;
            font-size: 1.6rem;
            width: 300px;
            @include media(){
                width: 100%;
            }
         }
    }

    &__bt_type_confirm {
       
    }

    &__btsItem_type_back {
        display: none;
    }

    &__btsItem_type_submit {
        display: none;
    }
}

.mw_wp_form {}

.mw_wp_form_input {}

.mwform-checkbox-field {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    label{
        display: flex;
        align-items: center;
    }
    
}

.horizontal-item {}

.mwform-checkbox-field-text {}

.mw_wp_form_confirm{
    .contact{
        &__dl{
            &:last-of-type{
                margin-bottom: 20px;
                @include media(){
                    margin-bottom: 15px;
                }
            }
        }
        &__bt_type_back{
            background-color: #eee;
            color: #ababab;
            border-color: #ababab;
        }
        &__btsItem_type_confirm {
            display: none;
        }
        &__btsItem_type_back {
            display: block;
        }
        &__btsItem_type_submit{
            display: block;
        }
        &__btsItem {
            max-width: 320px;
            width: 50%;
        }
    }
}